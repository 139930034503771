import React, { useEffect, useRef } from "react";
import { Link } from 'gatsby';

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';

//components

import ImageBackground from '../../components/imagebackground';
import SchoolCard from '../../components/motorrad/schoolcard';
import CourseGridItem from '../../components/coursegriditem';
import Carousel from '../../components/carousel';
import settings from '../../../settings';

//images
const onroadmotorcycle = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-one-day-on-road-motorcycle-course.jpg`;
const twodaymotorcycle = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-two-day-advanced-on-road-motorcycle.jpg`;
const usraauthority = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-usra-authority-school.jpg`;
const msfriding = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-msf-basic-riding-course.jpg`;
const offroadmotorcycle = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-one-day-off-corad-motorcycle.jpg`;
const twodayriding = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-two-day-off-road-motorcycle.jpg`;
const topbannerDesktop = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-classes-dirty-tire-top-banner.jpg`;
const topbannerMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/mobile/bmw-classes-dirty-tire-top-banner.jpg`;

const professionalinstructors = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/instructors-motorcycle-box.jpg`;
const basicfoundations = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/building-foundations-box.jpg`;
const onroadchallenges = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/on-road-challenges-box.jpg`;
const offroadchallenges = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/off-road-challenges-box.jpg`;
const caramederie = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/bmw-camraderie-box.jpg`;
const challengebychoice = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/challenge-by-choice-cone-box.jpg`;

const professionalinstructorsMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/instructors-motorcycle-box.jpg`;
const basicfoundationsMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/building-foundations-box-mobile.jpg`;
const onroadchallengesMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/on-road-challenges-box.jpg`;
const offroadchallengesMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/off-road-challenges-box.jpg`;
const caramederieMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/bmw-camraderie-box-mobile.jpg`;
const challengebychoiceMobile = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/challenge-by-choice-cone-box-mobile.jpg`;


const womensOneDay = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/womens-1-day.jpg`;
const womensTwoDay = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/womens-2-day.jpg`;
const womensThreeDay = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/womens-3-day.jpg`;

const slideone = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-motorcycle-class-slide01.jpg`;
const slidetwo = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-off-road-class-slide02.jpg`;
const slidethree = `${settings.IMAGES_BASE_URL}/v2/images/motorrad/classes/desktop/bmw-on-road-class-slide03.jpg`;
const carouselimages = [
  { id: 1, image: `${slideone}` },
  { id: 2, image: `${slidetwo}` },
  { id: 3, image: `${slidethree}` }
];


const scrollToRefDesktop = () => {
  let scrollDesktopValue = window.location.search.match(/\d+/g)[0];
  window.scrollTo({
    top: scrollDesktopValue,
    left: 0,
    behavior: 'smooth'
  });
};

const scrollToRefMobile = () => {
  let scrollMobileValue =window.location.search.match(/\d+/g)[1];
  window.scrollTo({
    top: scrollMobileValue,
    left: 0,
    behavior: 'smooth'
  });
};


const ClassesPage = () => {
  useEffect(() => {
    if(window.innerWidth < 1200 && window.location.search)
      scrollToRefMobile();
    else if(window.innerWidth >= 1200 && window.location.search)
      scrollToRefDesktop();
  });

  const contentRef = useRef(null)

  const scrollToContent= ()=>{
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Layout>
      <SEO title="The BMW Rider Academy School | On and Off-Road"  description="BMW motorrad training includes the basics, street survival, advanced on-road training, a USRA Authority course and even women's on and off-road classes!"/>
      <div className="motorrad-schools">
        <ImageBackground
          imageDesktop={topbannerDesktop}
          imageMobile={topbannerMobile}
          imageAlt="BMW Classes Dirty Tire Top Banner"
          scrollTo={scrollToContent}
        />
        <section className="school" ref={contentRef}>
          <div className="school__header">
            <h1>RIDER ACADEMY SCHOOLS</h1>
            <p>
              Our expertly trained instructors embolden you to step out of your comfort zone, push the limits, then push
              them some more. Where once fire roads were challenging, now tackle tight single-tracks with tenacity, and
              cone courses with poise.
            </p>
          </div>
          <div className="onroad">
            <h2>On-Road </h2>
            <SchoolCard
              rowType="row"
              image={msfriding}
              imageAlt="BMW MSF Basic Rider Course"
              header="TWO-DAY MSF BASIC RIDER COURSE"
              priceOne="$350 - INCLUDES USE OF A BMW G310R MOTORCYCLE"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"
              monthSliderQuery="&class=2_Day_MSF_Basic_Rider_Course"
            >
              <p>
                Learn where to begin, from the first grip of the handle bars. Start by completing an online course before
                you’re scheduled to take your class. Once here, you’ll have a short classroom session, then it’s time to
                ride. Spend over two days hands-on with a BMW G310R. Learn how to stay safe while riding on a
                closed-course environment, where your BMW Riding Instructor will guide you through the basics of
                straight-line riding, stopping, shifting and turning. Successful completion of this course may waive the license test in your state, and provide an
                insurance discount. Classes are held monthly.
              </p>
              <p>
                Thinking of getting a new BMW Bike? Our “Ride Smart Rewards” program can get you an MSF course credit and
                more.
              </p>
              <Link to="/motorrad/schoolfaq" className="bold-link">
                Read more about the MSF course in our FAQ.
              </Link>
            </SchoolCard>
            <SchoolCard
              rowType="row"
              image={onroadmotorcycle}
              imageAlt="BMW One Day on road motorcycle course"
              header="ONE-DAY STREET SURVIVAL ON-ROAD"
              priceOne="Price: $900"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=1_Day_Street_Survival_On-Road"
            >
              <p>
              This one-day course begins with a short classroom session, followed by going over your bike’s operations and track time. After a complimentary lunch, you’ll head back out for advanced exercises and some full laps around the track. We practice challenge by choice, which means we encourage students to go at a pace they feel comfortable with.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
            <SchoolCard
              rowType="row"
              image={twodaymotorcycle}
              imageAlt="BMW Two day Advanced on Road Motorcycle"
              header="TWO-DAY STREET SURVIVAL ON-ROAD"
              priceOne="Price: $1,800"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=2_Day_Street_Survival_On-Road"
            >
              <p>
              Two days of on-road riding instruction will saturate your brain and body with challenges specific to your skill set. Working one-on-one with a BMW USRA Pro, riders will build upon lessons learned from the first day and practice in those areas they feel need the most attention.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
            <SchoolCard
              rowType="row"
              image={usraauthority}
              imageAlt="BMW USRA Authority School"
              header="TWO-DAY USRA AUTHORITY SCHOOL"
              priceOne="Price: $1,800"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=2_Day_USRA_Authority_School"
            >
              <p>
                Originally constructed for the needs of LEO, government officers and security professionals, this
                technical set of exercises will assist riders in tight turns, slow speed navigation, sight lines and
                safety. Go from just a “rider” to an “operator” with an intense combination of challenges. Become one with
                your motorcycle and attain the fluidity to harmoniously balance your body, eyes, brain, and bike controls
                like a pro.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
          </div>
          <div className="offroad">
            <h2>Off-Road </h2>
            <SchoolCard
              rowType="row-reverse"
              image={offroadmotorcycle}
              imageAlt="BMW off road Motorcycle"
              header="ONE-DAY ADVENTURE OFF-ROAD"
              priceOne="Price: $900"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=1_Day_Adventure_Off-Road"
            >
              <p>
                Start this one-day session with some classroom instruction that covers the basics of off-road riding. Then, run through exercises that teach balance, control and confidence on varied surfaces. After a complimentary lunch, tackle unique off-road paths complete with ruts and washboards, then practice emergency braking. End the day a better rider than when you woke up.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
            <SchoolCard
              rowType="row-reverse"
              image={twodayriding}
              imageAlt="BMW Two day off road Motorcycle"
              header="TWO-DAY ADVENTURE OFF-ROAD"
              priceOne="Price: $1,800"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=2_Day_Adventure_Off-Road"
            >
              <p>
                This two-day offering will have you ready to tackle almost any remote location that you wish to transit. The first day will cover off-road foundations, while day two gets into more focused events like traversing steep inclines and declines, riding through mud, and picking up your bike while on a hill. The enhanced riding skills you learn will become as vital as your favorite pair of boots.
              </p>
              <p>Also available are All Women’s Off-Road Classes, featuring the same curriculum. Please ask for this specific program when calling for information.</p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
          </div>
          <div className="women-classes">
            <h2>WOMEN’S CLASSES</h2>
            <SchoolCard
              rowType="row"
              image={womensOneDay}
              imageAlt="BMW One day women's off-road new rider"
              header="ONE-DAY WOMEN’S OFF-ROAD NEW RIDER"
              priceOne="Price: $900"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=1_Day_Women’s_Only_Off-Road_New_Adventure_Rider"
            >
              <p>
                If you’re ready to tackle the unpaved world but not sure where to begin, this is the class 
                for you. Learn essential skills needed to leave the pavement and explore what you’ve been 
                missing while in an stress-free environment. Receive personal attention at a pace designed 
                to build your confidence. In this class, you’ll review proper body position while riding, 
                steering and stopping a BMW motorcycle, along with picking up a bike in the event of a tip 
                over. You’ll also traverse uneven surfaces and take Enduro rides on select trails to help 
                you practice your new skills in a real-world setting. By the end, you’ll be well prepared 
                for your first adventure.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
            <SchoolCard
              rowType="row"
              image={womensTwoDay}
              imageAlt="BMW Two day women's off-road foundations"
              header="TWO-DAY WOMEN’S OFF-ROAD FOUNDATIONS"
              priceOne="Price: $1,800"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=2_Day_Women’s_Only_Off-Road_Adventure_Rider_Foundations"
            >
              <p>
                This two-day course focuses on the fundamentals of off-road adventure riding. Your 
                confidence will build as you tackle ride and steering exercises, then progress to varying 
                obstacles like washboards and ruts. You’ll also practice ascending and descending hills by 
                starting with small humps that allow you to perfect your body position on a motorcycle. 
                The more comfortable you get, the bigger the hills become. You’ll finish two days of 
                training with a valuable emergency braking exercise, learning to effectively stop your 
                adventure bike off-road. Finally, ride our entire 137-acre property, putting your new skills
                to use in a real-world environment.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
            <SchoolCard
              rowType="row"
              image={womensThreeDay}
              imageAlt="BMW Three day women's all-in adventure riding"
              header="THREE-DAY WOMEN’S ALL-IN ADVENTURE RIDING"
              priceOne="Price: $2,700"
              monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE DATES"
              monthSliderQuery="&class=3_Day_Women’s_Only_Off-Road_All_In_Adventure_Riding"
            >
              <p>
                For those looking for the ultimate adventure, it’s here. This three-day class will break down
                off-road riding into blocks you can build on. Spend your first day reviewing the foundations 
                of off-road, including slow rides, trial stops, steering, washboards, ruts and camel humps. 
                Day two gets you comfortable with emergency braking on different surface types, along with 
                mounting from the side of a motorcycle and riding in deep gravel and sand. Day three continues
                 to build with more advanced concepts such as riding onto bigger hills and into water 
                 crossings. Throughout your class, you’ll ride trails through woods and varying surfaces, 
                 demonstrating that with proper off-road training, you can accomplish things you never 
                 thought possible on a BMW.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </SchoolCard>
          </div>
        </section>

        <section className="coursehiglight">
          <div className="container">
            <div className="coursehighlight__header">
              <h2>What You'll Learn</h2>
            </div>
          </div>
          <div className="coursegrid">
            <div className="row coursegrid__top">
              <CourseGridItem
                cardImage={professionalinstructors}
                cardMobileImage={professionalinstructorsMobile}
                cardTitle="PROFESSIONAL INSTRUCTORS"
                cardText="With many years of combined experience both on- and off-road, they are able to demonstrate, guide and assist you on new adventures. Their friendly banter will encourage you to ask questions and learn from shared experience."
                justifyContent="center"
                alignItems="flex-start"
                width="50"
              />
              <CourseGridItem
                cardImage={basicfoundations}
                cardMobileImage={basicfoundationsMobile}
                cardTitle="BUILDING BASIC FOUNDATIONS"
                cardText="Our approach to training is to start from A and move to Z. Clutch and throttle control, low-speed maneuverability and balance are studied, practiced and then applied in scenarios that mimic the real world."
                justifyContent="flex-end"
                alignItems="flex-start"
                width="80"
              />
            </div>
            <div className="row">
              <div className="coursewrapper-one row">
                <CourseGridItem
                  cardImage={onroadchallenges}
                  cardMobileImage={onroadchallengesMobile}
                  cardTitle="ON-ROAD CHALLENGES"
                  cardText="Our classes are developed to prepare our students for real-life scenarios. Techniques such as discovering sight lines, understanding navigation, tight turns and slow-speed maneuvers are practiced in a controlled setting with our instructions. New techniques start as challenges but soon become foundational."
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="80"
                />
                <CourseGridItem
                  cardImage={offroadchallenges}
                  cardMobileImage={offroadchallengesMobile}
                  cardTitle="OFF-ROAD CHALLENGES"
                  cardText="You learn by doing here, and we’ve got many off-road challenges that will leave you both dirty and smiling. Climb and descend hills in a controlled manner, avoid obstacles and conquer fear with our instructors’ aid."
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="80"
                />
              </div>
              <div className="coursewrapper-two row">
                <CourseGridItem
                  cardImage={caramederie}
                  cardMobileImage={caramederieMobile}
                  cardTitle="CAMARADERIE"
                  cardText="There is an inherent camaraderie that exists among all riders. Couple that with challenging sessions and this camaraderie multiplies as students and instructors share experiences. You’ll encourage each other with teamwork and experience unique perspectives to grow as a rider. Come away with new friends and riding mates from all over the United States."
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  width="80"
                />
                <CourseGridItem
                  cardImage={challengebychoice}
                  cardMobileImage={challengebychoiceMobile}
                  cardTitle="CHALLENGE BY CHOICE"
                  cardText="We encourage our students to challenge themselves and expand their riding experience at a pace that is comfortable for each individual student."
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  width="60"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="bottom-section">
          <Carousel images={carouselimages} />
        </div>
      </div>
    </Layout>
  );
}

export default ClassesPage;
